@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
body{
    font-family: 'Poppins', sans-serif;
    background-color: #141526;
}
.center-screen{
    display: flex;
    justify-content: center;
    align-items: center;
}
